import React from "react"
import Container from "react-bulma-components/lib/components/container/container";
import Content from "react-bulma-components/lib/components/content/content";
import Columns from "react-bulma-components/lib/components/columns/columns";
import Column from "react-bulma-components/lib/components/columns/components/column";
import Button from "react-bulma-components/lib/components/button/button";

import Page from "../components/page"

import * as Styles from "./markdown-page.module.scss";


const MarkdownPage = (data) =>
	<Page disableIndentation>
		<Container>
			<Columns>
				<Column className={ "is-8-desktop" }>
					<Button
						size={ "medium" }
						className={ Styles.button }
						color={ "primary" }
						renderAs={ "a" }
						href={ "/" }>
						← Zur Startseite
					</Button>

					<Content
						size={ "medium" }
						className={ Styles.content }
						dangerouslySetInnerHTML={ { __html: data.pageContext.html } }
					/>
				</Column>
			</Columns>
		</Container>
	</Page>

export default MarkdownPage
